<template>
  <div>
    <h4>Toplam Siparişler</h4>
    <p>Her günün raporu bir sonraki gün 08:00'da oluşur.</p>
    <div>
      <label for="startDate">Başlangıç Tarihi:</label>
      <input type="date" id="startDate" v-model="startDate" :min="startDateLimit" :max="yesterdayDate" class="datepicker-input">
      <label for="endDate">Bitiş Tarihi:</label>
      <input type="date" id="endDate" v-model="endDate" :min="startDateLimit" :max="yesterdayDate" class="datepicker-input">
      <button @click="filterByDateRange">Tarih Aralığını Uygula</button>
    </div>

    <div v-if="noData" style="margin-top: 36px;">
      <p>Seçilen tarih aralığında kayıt bulunmamaktadır.</p>
    </div>

    <table v-else border="1">
      <thead>
        <tr>
          <th style="color: black;">Toplam Sipariş Adedi</th>
          <th style="color: black;">Toplam Sipariş Tutarı</th>
          <th style="color: black;">Toplam Maliyet</th>
          <th style="color: black;">Toplam Kargo Ücreti</th>
          <th style="color: black;">Toplam Komisyon</th>
          <th style="color: black;">Toplam Net Kar</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totalOrders }}</td>
          <td>{{ totalSalesAmount.toFixed(2) }} TRY</td>
          <td>{{ totalCost.toFixed(2) }} TRY</td>
          <td>{{ totalCargo.toFixed(2) }} TRY</td>
          <td>{{ totalCommission.toFixed(2) }} TRY</td>
          <td>{{ totalNetProfit.toFixed(2) }} TRY</td>
        </tr>
      </tbody>
    </table>

    <h4>Siparişler Detay</h4>
    <table v-if="filteredOrders.length > 0" border="1">
      <thead>
        <tr>
          <th style="color: black;">Sipariş Numarası</th>
          <th style="color: black;">Sipariş Tarihi</th>
          <th style="color: black;">Ürün Adı</th>
          <th style="color: black;">Adet</th>
          <th style="color: black;">Satış Fiyatı</th>
          <th style="color: black;">Maliyet</th>
          <th style="color: black;">Kargo Ücreti</th>
          <th style="color: black;">Komisyon</th>          
          <th style="color: black;">Sipariş Tutarı</th>
          <th style="color: black;">Net Kâr</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in filteredOrders" :key="order.id">
          <td>{{ order.orderNumber }}</td>
          <td>{{ formatDate(order.orderDate) }}</td>
          <td class="product-name">
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ line.productName }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ line.quantity }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ line.price }}</li>
            </ul>
          </td>
          <td>{{ order.lines.reduce((acc, line) => acc + (parseFloat(line.cost) * parseFloat(line.quantity)), 0).toFixed(2) }}</td>
          <td>{{ calculateCargo(parseFloat(order.totalPrice)).toFixed(2) }}</td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ formatCurrency(calculateCommissionInTL(line)) }}</li>
            </ul>
          </td>
          <td>{{ order.totalPrice.toFixed(2) }}</td> 
          <td>{{ calculateNetProfit(order) }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>Seçilen tarih aralığında kayıt bulunmamaktadır.</p>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      orders: [],
      filteredOrders: [],
      totalOrders: 0,
      totalSalesAmount: 0,
      totalCost: 0,
      totalCargo: 0,
      totalCommission: 0,
      totalNetProfit: 0,
      filter: 'daily',
      startDate: '',
      endDate: '',
      noData: false,
      startDateLimit: '2024-07-15', // Minimum selectable date
      yesterdayDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD') // Maximum selectable date
    };
  },
  created() {
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      try {
        const response = await axios.get('https://kitpanel-33ba2-default-rtdb.firebaseio.com/ty_data.json');
        this.orders = Object.values(response.data).map(order => {
          return {
            ...order,
            orderDate: dayjs(order.orderDate).subtract(3, 'hour').valueOf(),
            lines: order.lines.map(line => ({
              ...line,
              cost: 0,
              cargo: 0,
              comission: 0
            }))
          };
        });
        await this.fetchCargoCosts(); // Fetch cargo costs for all lines
      } catch (error) {
        console.error("API'dan veri çekilirken hata oluştu:", error);
      }
    },
    async fetchCargoCosts() {
      const cargoUrl = `https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json`;
      try {
        const response = await fetch(cargoUrl);
        if (!response.ok) {
          throw new Error('Could not fetch cargo costs');
        }
        const cargoData = await response.json();

        this.orders.forEach(order => {
          order.lines.forEach(line => {
            const item = cargoData.find(item => item.barcode === line.barcode);
            if (item) {
              line.cargo = parseFloat(item.cargo) || 0;
              line.comission = parseFloat(item.comission) || 0;
              line.cost = parseFloat(item.cost) || 0;
            } else {
              line.cargo = 0;
              line.comission = 0;
              line.cost = 0;
            }
          });
        });
      } catch (error) {
        console.error('Error fetching cargo costs:', error);
        this.orders.forEach(order => {
          order.lines.forEach(line => {
            line.cargo = 0;
            line.comission = 0;
            line.cost = 0;
          });
        });
      }
    },
    filterByDateRange() {
      const start = this.startDate ? dayjs(this.startDate).startOf('day').valueOf() : null;
      const end = this.endDate ? dayjs(this.endDate).endOf('day').valueOf() : null;

      if (!start || !end) {
        alert('Geçerli bir tarih aralığı girin.');
        return;
      }

      // Check if the end date is today
      const today = dayjs().startOf('day').valueOf();
      if (end === today) {
        alert('Henüz rapor oluşmadı.');
        return;
      }

      // Check if the start or end date is before the allowed start date
      if (start < dayjs(this.startDateLimit).startOf('day').valueOf() || end < dayjs(this.startDateLimit).startOf('day').valueOf()) {
        alert('Başlangıç tarihi 14.07.2024 tarihinden önce olamaz.');
        return;
      }

      this.filteredOrders = this.orders.filter(order => {
        return order.orderDate >= start && order.orderDate <= end;
      });

      this.noData = this.filteredOrders.length === 0; // Set noData flag

      if (!this.noData) {
        this.calculateTotals();
      }
    },
    calculateTotals() {
      let filteredOrders = [];

      switch (this.filter) {
        case 'daily':
          filteredOrders = this.orders.filter(order =>
            dayjs(order.orderDate).subtract(3, 'hour').isSame(dayjs(), 'day')
          );
          break;
        case 'weekly':
          filteredOrders = this.orders.filter(order =>
            dayjs(order.orderDate).subtract(3, 'hour').isSame(dayjs(), 'week')
          );
          break;
        case 'monthly':
          filteredOrders = this.orders.filter(order =>
            dayjs(order.orderDate).subtract(3, 'hour').isSame(dayjs(), 'month')
          );
          break;
        case 'yearly':
          filteredOrders = this.orders.filter(order =>
            dayjs(order.orderDate).subtract(3, 'hour').isSame(dayjs(), 'year')
          );
          break;
        default:
          filteredOrders = this.orders;
      }

      this.filteredOrders = this.filteredOrders.length > 0 ? this.filteredOrders : filteredOrders;
      this.totalOrders = this.filteredOrders.length;

      this.totalSalesAmount = this.filteredOrders.reduce((acc, order) => acc + parseFloat(order.totalPrice), 0);
      this.calculateTotalCost();
      this.calculateTotalCargo();
      this.calculateTotalCommission();
      this.calculateTotalNetProfit();
    },
    calculateTotalCost() {
      this.totalCost = this.filteredOrders.reduce((acc, order) => {
        return acc + order.lines.reduce((lineAcc, line) => lineAcc + (parseFloat(line.cost) * parseFloat(line.quantity)), 0);
      }, 0);
    },
    calculateTotalCargo() {
      this.totalCargo = this.filteredOrders.reduce((acc, order) => {
        const totalPrice = parseFloat(order.totalPrice);
        if (!isNaN(totalPrice)) {
          return acc + this.calculateCargo(totalPrice);
        } else {
          console.error('Invalid totalPrice value:', totalPrice);
          return acc;
        }
      }, 0);
    },
    calculateCargo(totalPrice) {
      // Calculate cargo fee based on totalPrice
      if (totalPrice >= 0 && totalPrice <= 125) {
        return 30;
      } else if (totalPrice > 125 && totalPrice <= 200) {
        return 54;
      } else {
        return 60;
      }
    },
    calculateTotalCommission() {
      this.totalCommission = this.filteredOrders.reduce((acc, order) => {
        return acc + order.lines.reduce((lineAcc, line) => {
          return lineAcc + (parseFloat(line.price) * parseFloat(line.quantity) * (parseFloat(line.comission) / 100));
        }, 0);
      }, 0);
    },
    calculateTotalNetProfit() {
      this.totalNetProfit = this.totalSalesAmount - (this.totalCost + this.totalCargo + this.totalCommission);
    },
    formatDate(timestamp) {
      return dayjs(timestamp).format('DD/MM/YYYY HH:mm:ss');
    },
    calculateNetProfit(order) {
      const totalCost = order.lines.reduce((acc, line) => acc + (parseFloat(line.cost) * parseFloat(line.quantity)), 0);
      const totalCargo = this.calculateCargo(parseFloat(order.totalPrice));
      const totalCommission = order.lines.reduce((acc, line) => acc + parseFloat(line.comission), 0);
      const netProfit = parseFloat(order.totalPrice) - (totalCost + totalCargo + totalCommission);
      return netProfit.toFixed(2);
    },
    calculateCommission(line) {
      // Calculate commission for a single line item
      return parseFloat(line.price) * parseFloat(line.quantity) * (parseFloat(line.comission) / 100);
    },
    calculateCommissionInTL(line) {
      // Calculate commission in TL for a single line item
      const commissionInTL = this.calculateCommission(line);
      return commissionInTL;
    },
    formatCurrency(value) {
      // Format number as currency (Turkish Lira)
      const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
      });
      return formatter.format(value);
    },
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f4f4f4;
}
button {
  margin: 5px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
a {
  color: #007bff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.datepicker-input {
  background-color: white;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd; /* İnce bir çizgi ekleyin */
  padding-bottom: 5px; /* Çizgi ile metin arasında boşluk bırakın */
}
ul li:last-child {
  border-bottom: none; /* Son eleman için çizgi eklemeyin */
}

/* Ürün adı sütununu sola hizala */
table td.product-name {
  text-align: left;
}
</style>
